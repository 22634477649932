import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, TabContent, TabPane, Nav, NavItem, NavLink, Form, Col, ButtonToolbar, Button } from 'reactstrap';
import { useForm, FormProvider, Controller } from "react-hook-form";
import defaultJSON from './defaultJSON.json';
import { callAPI } from '../../helpers/api';
import { CustomSpinner } from '../common/CustomSpinner';
import { LogColumns, ApiDataColumns } from './Columns';
import { LogStatusText, UserInputTypeText } from '../../helpers/enums';
import TableFiltersAndButtons from '../common/TableFiltersAndButtons';
import { CustomSimpleEditor } from '../common/CustomEditor';

function compare(a, b) {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }

    return 0;
}

export default function Query({
    msalInstance,
    onRun
}) {
    let currentUser = useSelector(state => state.application.user);
    let activeQuery = useSelector(state => state.inspector.activeItem);
    let formHidden = useSelector(state => state.inspector.formHidden);
    const methods = useForm();
    const initialJSON = JSON.stringify(defaultJSON, undefined, 2); //console.log('initial JSON', initialJSON);
    const [activeTab, setActiveTab] = useState('1');
    const [request, setRequest] = useState(activeQuery.request ?? initialJSON); //console.log('request', request);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [logResults, setLogResults] = useState([]);
    const [errorMsg, setErrorMsg] = useState();
    const [validationError, setValidationError] = useState();

    const toggleTab = (tab) => {
        if ((activeTab !== tab)) {
            setActiveTab(tab);
        }
    }

    const getLogRows = (logs, type, desc = "") => {
        //console.log('logs', logs);
        if (logs?.length > 0) {
            return logs.sort(compare).map(l => {
                return {
                    batch: desc,
                    type: type,
                    message: l
                };
            });
        }
        return [];
    }

    const getLogArray = (arr, logs, type, desc = "") => {
        if (logs?.length > 0) {
            if (arr?.length > 0) {
                return [...arr, ...getLogRows(logs, type, desc)];
            }

            return getLogRows(logs, type, desc);
        }

        return arr;
    }

    const setResultByItemAndType = (arr, list, item, type, desc, storageItems) => {
        const getItem = (code) => {
            if (storageItems?.length > 0) {
                return storageItems.find(i => i.code === code) ?? {};
            }
            return {};
        }
        if (list?.length > 0) {
            list.forEach(s => {
                const itemData = getItem(item);
                arr.push({
                    batch: desc,
                    category: itemData.category ?? "",
                    item: item,
                    description: itemData.description ?? "",
                    element: s.code,
                    type: type,
                    value: s.value ?? "",
                    unit: s.unit,
                    published: s.published
                });
            });
        }
    }

    const tryGetJson = (str) => {
        try {
            var convertedJson = JSON.parse(str);
            return { error: false, value: convertedJson };
        } catch (e) {
            return { error: true };
        }
    }

    const onSubmit = async () => {
        setErrorMsg();
        setLogResults([]);
        setResults([]);
        setLoading(true);
        var jsonResult = tryGetJson(request);
        if (jsonResult.error) {
            setValidationError('Json is not valid!');
            setLoading(false);
            return;
        }
        var prettyRequest = JSON.stringify(jsonResult.value, undefined, 2);
        setRequest(prettyRequest);
        let result = await callAPI('query/' + activeQuery.id, msalInstance, currentUser ? currentUser.authUser : {}, jsonResult.value);
        if (result.errorMsg && !result.json) {
            setErrorMsg(result.errorMsg);
        }
        else {
            var resultData = result.json; //console.log('resultData', resultData);
            if (resultData?.elements?.isSuccessful === true) {
                // Data was returned
                let logArr = getLogArray([], resultData.elements.errorMsgs, LogStatusText.ERROR, "Whole request");
                logArr = getLogArray(logArr, resultData.elements.infoMsgs, LogStatusText.INFO, "Whole request");
                if (resultData.elements.batches?.length > 0) {
                    // Let's collect the log information and results
                    let resultArr = [];
                    const allStorageItems = resultData.storageItems ?? [];
                    resultData.elements.batches.forEach(d => {
                        const description = d.request?.description ?? ""; //console.log('description', description);
                        logArr = [
                            ...logArr,
                            ...getLogRows(d.errorMsgs, LogStatusText.ERROR, description),
                            ...getLogRows(d.warningMsgs, LogStatusText.WARNING, description),
                            ...getLogRows(d.infoMsgs, LogStatusText.INFO, description)
                        ];
                        // Set the result rows
                        const storageItems = allStorageItems.find(i => i.storage === d.request.storage && i.published === d.request.published)?.items ?? []; //console.log('storageItems', storageItems);
                        let batchArr = [];
                        for (const [key, values] of Object.entries(d.results)) {
                            //console.log('key, values', key, values);
                            setResultByItemAndType(batchArr, values?.str, key, UserInputTypeText.STRING, description, storageItems);
                            setResultByItemAndType(batchArr, values?.int, key, UserInputTypeText.INTEGER, description, storageItems);
                            setResultByItemAndType(batchArr, values?.flt, key, UserInputTypeText.FLOAT, description, storageItems);
                            setResultByItemAndType(batchArr, values?.arrStr, key, UserInputTypeText.STRING_ARRAY, description, storageItems);
                            setResultByItemAndType(batchArr, values?.arrInt, key, UserInputTypeText.INTEGER_ARRAY, description, storageItems);
                            setResultByItemAndType(batchArr, values?.arrFlt, key, UserInputTypeText.FLOAT_ARRAY, description, storageItems);
                            setResultByItemAndType(batchArr, values?.dictStrStr, key, UserInputTypeText.STRING_DICT, description, storageItems);
                            setResultByItemAndType(batchArr, values?.dictStrInt, key, UserInputTypeText.INTEGER_DICT, description, storageItems);
                            setResultByItemAndType(batchArr, values?.dictStrFlt, key, UserInputTypeText.FLOAT_DICT, description, storageItems);
                            setResultByItemAndType(batchArr, values?.dictStrArrStr, key, UserInputTypeText.STRING_ARRAY_DICT, description, storageItems);
                            setResultByItemAndType(batchArr, values?.dictStrArrInt, key, UserInputTypeText.INTEGER_ARRAY_DICT, description, storageItems);
                            setResultByItemAndType(batchArr, values?.dictStrArrFlt, key, UserInputTypeText.FLOAT_ARRAY_DICT, description, storageItems);
                        }
                        // We need to sort item elements by category, item code, element code, type and value
                        resultArr.push(...(batchArr.sort((a, b) => {
                            // First category 
                            if (a.category < b.category) return -1;
                            if (a.category > b.category) return 1;
                            // Second item code
                            if (a.item < b.item) return -1;
                            if (a.item > b.item) return 1;
                            // Then element code
                            if (a.element < b.element) return -1;
                            if (a.element > b.element) return 1;
                            // Then type
                            if (a.type < b.type) return -1;
                            if (a.type > b.type) return 1;
                            // Then value
                            //console.log('a, b', String(a.value), String(b.value));
                            if (String(a.value) < String(b.value)) return -1;
                            if (String(a.value) > String(b.value)) return 1;
                            return 0;
                        })));
                    });
                    setResults(resultArr); //console.log('resultArr', resultArr);
                }
                setLogResults(logArr);
            }
        }
        setLoading(false);
        toggleTab('2');
        if (onRun) {
            onRun({
                ...activeQuery,
                request: prettyRequest
            });
        }
    }

    const handleRequestChange = (r) => {
        setRequest(r);
        setValidationError();
    }

    const validateRequest = (event) => {
        if (event.target.value) {
            //console.log('Validate request', event.target.value); TODO!!!
        }
    }

    const resetJSON = () => {
        setRequest(initialJSON);
    }

    useEffect(() => {
        var query = activeQuery.request ?? initialJSON; //console.log('request', query);
        methods.setValue('request', query);
        setRequest(query);
        setLogResults([]);
        setResults([]);
        toggleTab('1');
    }, [activeQuery.id]); // eslint-disable-line react-hooks/exhaustive-deps

    if (formHidden) {
        return;
    }
    return (
        <Container fluid={true}>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={activeTab === '1' ? "active" : ""}
                        onClick={() => toggleTab('1')}
                    >
                        Input
                    </NavLink>
                </NavItem>
                <NavItem className={logResults?.length > 0 || errorMsg?.length > 0 ? "" : "d-none" }>
                    <NavLink
                        className={activeTab === '2' ? "active" : ""}
                        onClick={() => toggleTab('2')}
                    >
                        Log
                    </NavLink>
                </NavItem>
                <NavItem className={logResults?.length > 0 || errorMsg?.length > 0 ? "" : "d-none" }>
                    <NavLink
                        className={activeTab === '3' ? "active" : ""}
                        onClick={() => toggleTab('3')}
                    >
                        Output
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Row>
                        <Col sm="12" md="10" lg="8" xl="6">
                            <FormProvider {...methods}>
                                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                                    <div className="container-editor editor-lg form-control mt-3">
                                        <Controller name="request"
                                            control={methods.control}
                                            defaultValue={request}
                                            render={({ field }) =>
                                                <CustomSimpleEditor {...field}
                                                    value={request}
                                                    onChange={q => handleRequestChange(q)}
                                                    onBlur={validateRequest}
                                                />
                                            }
                                        />
                                    </div>
                                    {validationError && <p className="text-danger">{validationError}</p>}
                                    {loading ? <CustomSpinner margin={true} /> :
                                        <ButtonToolbar className="mt-3 mb-5">
                                            <Button
                                                color="primary"
                                                type="submit"
                                                className="me-1"
                                                disabled={validationError?.length > 0}
                                            >Run
                                            </Button>
                                            <Button color="primary" onClick={resetJSON}>Clear</Button>
                                        </ButtonToolbar>
                                    }
                                </Form>
                            </FormProvider>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                    <Row>
                        <Col sm="12">
                            <Container fluid={true} className="mb-4 ps-0 pe-0">
                                <Col lg={12}>
                                    <TableFiltersAndButtons
                                        title="Messages"
                                        data={logResults}
                                        columns={LogColumns()}
                                        loading={loading}
                                        localStorageKey="inspectorLogTable"
                                        pageSizes={[10, 20, 30]}
                                        msg={errorMsg}
                                        useRowSelection={false}
                                    />
                                </Col>
                            </Container>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="3">
                    <Row>
                        <Col sm="12">
                            <Container fluid={true} className="mb-4 ps-0 pe-0">
                                <Col lg={12}>
                                    <TableFiltersAndButtons
                                        title="Elements"
                                        data={results}
                                        columns={ApiDataColumns()}
                                        loading={loading}
                                        localStorageKey="inspectorResultTable"
                                        pageSizes={[10, 20, 30]}
                                        tableXl={12}
                                        useRowSelection={false}
                                    />
                                </Col>
                            </Container>
                            {/*{ JSON.stringify(results) }*/}
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
        </Container>
    );
}